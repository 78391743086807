<template>
  <div class="adult-certification">
    <div class="wrapper">
      <div class="top">
        <div class="wrapper">
          <div class="title">성인 인증</div>
          <div class="desc">
            <span>본 프로젝트는</span>
            <span class="color-danger bold"> 만 19세 이상 </span>
            <span>참여가 가능하므로 나이 확인을 위한 인증절차가 필요합니다.</span>
          </div>
        </div>
      </div>
      <ul class="guide">
        <li>
          <span>최초 인증일로부터 1년간 오마이컴퍼니 내 모든 성인 서비스를 추가적인 인증없이 이용하실 수 있으며 1년 경과 시 갱신이 필요합니다.</span>
        </li>
        <li>
          <span>"청소년"이란 만 19세 미만인 사람을 말합니다. 다만, 만 19세가 되는 해의 1월 1일을 맞이한 사람은 제외합니다. (청소년 보호법 제2조 1항)</span>
        </li>
        <li>
          <span>본 인증 서비스는 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 및 청소년 보호법 등 관련 법률에 의거하여 시행되는 절차입니다.</span>
        </li>
        <li>
          <span>투자자 인증 서비스와는 별도로 진행되는 절차입니다.</span>
        </li>
        <li>
          <span>인증 절차에서 수집된 내용이 회원 정보에 반영될 수 있습니다.</span>
        </li>
        <li>
          <span>본 인증 절차는 인증을 진행하는 본인 이외의 타인의 명의를 사용하실 수 없습니다.</span>
        </li>
      </ul>
      <div class="accordion agreement" :id="`${component.name}Accordion`">
        <div class="item">
          <div class="parent" :id="`${component.name}Parent`">
            <div class="form-group">
              <input type="checkbox" :id="`${component.name}Agree`" :checked="state.agreed" @change="onChangeAgreed($event)">
              <label class="form-check-label" :for="`${component.name}Agree`">개인정보 수집 및 이용 동의</label>
            </div>
            <button class="btn collapsed" type="button" data-toggle="collapse" :data-target="`#${component.name}Child`" :aria-controls="`#${component.name}Child`" aria-expanded="true">
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}Child`" class="collapse" :aria-labelledby="`${component.name}Parent`" :data-parent="`#${component.name}Accordion`">
            <div class="child">
              <div>
                <span class="title">수집/이용 목적</span>
                <span>이용자 식별 및 연령확인, 성인인증 이력관리</span>
              </div>
              <div>
                <span class="title">수집 항목</span>
                <span>이름, 성별, 생년월일, 내/외국인 구분, 휴대폰 번호, 통신사</span>
              </div>
              <div>
                <span class="title">보유 및 이용기간</span>
                <span>회원탈퇴 또는 서비스 종료시까지(단, 관계법령에 정해진 규정에 따라 법정기간 동안 보관)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="row">
        <div class="col pr-0">
          <router-link class="btn btn-block btn-secondary font-sm" :to="`/${modalParams.projectType}/${modalParams.projectSeq}`">이전 페이지로 이동</router-link>
        </div>
        <div class="col pl-0">
          <button class="btn btn-block btn-point font-sm" :disabled="!state.agreed" @click="openNiceAuth()">휴대폰으로 인증</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "modalAdultCertification";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});

      http.get("/api/investor/auth-module").then(({data}) => {
        state.encodeData = data.body.sEncData;
      });

      state.niceAuthFlag = "N";
    });

    const state = reactive({
      agreed: false,
      form: {
        investorName: "",
        ceoName: "",
        memberType: "",
        token: "", // 나이스 본인 인증 완료 후 서버에서 생성한 토큰
      },
      nice: {
        encodeData: "",
        niceAuthFlag: "N",
      },
    });

    const modalParams = store.getters.modalParams(component);

    const onChangeAgreed = (e) => {
      e.target.checked ? state.agreed = true : state.agreed = false;
    };

    const openNiceAuth = () => {
      if (!state.agreed) {
        return store.commit("개인정보 수집 및 이용에 동의해주세요.");
      }

      const ua = navigator.userAgent.toLowerCase();
      const isNaverApp = /naver/.test(ua);

      let popUrl = "about:blank";

      if (lib.isAndroidMobile() && isNaverApp) {
        popUrl = "";
      }

      const popupName = "popupChk";
      const nicePopupWindow = window.open(popUrl, popupName, "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");

      if (!lib.isOmcApp() || !lib.isAppleMobile()) {
        if (nicePopupWindow == null) {
          return store.commit("setSwingMessage", "팝업 차단기능 혹은 팝업 차단 프로그램이 동작 중입니다. 팝업 차단을 해제하신 후 다시 시도해주세요.");
        }

        const args = {
          EncodeData: state.encodeData,
          m: "checkplusSerivce",
          param_r1: "adult", // 매개변수1(인증 구분 값)
        };

        const form = document.createElement("form");
        form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        form.method = "post";
        form.target = popupName;

        for (let i in args) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = i;
          input.value = args[i];
          form.append(input);
        }

        document.body.append(form);

        window.niceCertCallback = (res) => {
          if (res?.code === "0") {
            if (res.message === "BAD_REQUEST_UNDERAGE") {
              store.commit("setSwingMessage", "만 19세 미만입니다.");
              return router.push(`/${modalParams.projectType}/${modalParams.projectSeq}`);
            }
            // if (store.state.account.memberName !== res.name) {
            //   store.commit("closeModal", {
            //     name: component.name,
            //     onClose() {
            //       const message = `회원의 계정 정보(${store.state.account.memberName})가 인증 받으신 정보(${res.name})와 다릅니다. 타인의 명의를 이용하여 인증을 완료할 경우 처벌될 수 있습니다.`;
            //       store.commit("setSwingMessage", message);
            //     }
            //   });
            //   return;
            // }
            state.niceAuthFlag = "Y";
            store.commit("closeModal", {name: component.name});
          }
          store.commit("setSwingMessage", res.message);
        };

        form.submit();
        form.remove();
      }
    };

    return {component, state, modalParams, onChangeAgreed, openNiceAuth};
  },
});
</script>

<style lang="scss" scoped>
.adult-certification {
  > .wrapper {
    padding: $px25 $px25 $px30 $px25;
    background: #fff;
    min-height: $px100;

    > .top {
      .title {
        font-size: $px22;
        margin-bottom: $px11;
      }
    }

    .guide {
      font-size: $px14;
      margin-top: $px20;
      font-weight: 300;
      padding-left: $px25;

      li {
        margin-top: $px4;
      }
    }

    .agreement {
      border: $px1 solid $colorBorder;
      border-radius: $px4;
      padding: $px20 $px15;
      margin-top: $px20;

      .item {
        .parent {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          > .form-group {
            display: flex;
            align-items: center;
            gap: $px7;
            margin: 0;

            label {
              line-height: 1;
              font-size: $px15;
            }
          }

          .btn {
            position: absolute;
            top: 0;
            margin-top: $px-12;
            right: 0;
            padding: $px10;

            > i {
              transition: 0.33s;
            }

            &.collapsed > i {
              transform: rotate(180deg);
            }
          }
        }

        .child {
          padding-top: $px6;

          > div {
            font-size: $px12;
            color: $colorSecondary;
            margin-top: $px10;

            > .title {
              color: initial;
              display: block;
              font-size: $px14;
            }
          }
        }
      }
    }
  }

  .actions {
    position: relative;

    .row {
      .btn {
        padding: $px15;
        height: $px56;
        border-radius: 0;
      }
    }
  }

  @media (max-width: 991px) {
    > .top {
      .emphasis {
        font-size: $px20;
      }

      .notice {
        font-size: $px15;
      }
    }

    .guide {
      font-size: $px13;
    }

    .agreement {
      .item {
        .parent {
          > .form-group {
            input[type=checkbox] {
              width: $px18;
              height: $px18;
            }

            label {
              font-size: $px15;
            }
          }
        }
      }
    }
  }
}
</style>